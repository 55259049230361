import { useRef } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Table, Form, Input, Typography, InputNumber, Button } from 'antd'
import Checkbox from 'app/components/Checkbox/index'
import { useTranslation } from 'react-i18next'

import { useCurrency } from 'app/hooks/useCurrency'
import { CannedService } from 'app/api/Models/CannedServiceModel'
import { useCanneryServicesContext } from 'app/providers/CannedServicesProvider'
import { useDataSource } from 'app/screens/cannedService/hooks/useDataSource'

import styles from 'app/screens/cannedService/serviceTables/styles.module.scss'

const { Text } = Typography
const { Summary } = Table
const { Cell, Row } = Summary

function TableSummary() {
	const { t } = useTranslation()
	const { formatCurrency } = useCurrency()
	const { isFixedPrice, subtotals, fixedPricinglaborSubtotal } =
		useCanneryServicesContext()
	const { dataSourceWithKeys, handleAdd } = useDataSource(CannedService.LABORS)
	const [form] = Form.useForm()
	const nameRef = useRef(null)

	const total = isFixedPrice
		? fixedPricinglaborSubtotal
		: (subtotals[CannedService.LABORS] ?? 0)

	const addItem = async () => {
		const values = await form.validateFields()
		const numberOfItems = dataSourceWithKeys.length ?? 0
		handleAdd({
			key: (numberOfItems + 1).toString(),
			...values,
			taxable: !values.taxable
		})
		form.resetFields()
		nameRef.current!.focus()
	}

	return (
		<Summary>
			<Form form={form} component={false}>
				<Row className={styles.newItemRow}>
					<Cell index={-1} />
					<Cell index={0}>
						<Form.Item
							name="name"
							rules={[
								{
									required: true,
									message: t('canned_service.error.min_one_character')
								}
							]}>
							<Input
								ref={nameRef}
								data-testid="new-row-title"
								className={styles.titleCell}
								placeholder={t('canned_service.labors.empty_title')}
							/>
						</Form.Item>
					</Cell>
					<Cell index={2} />
					<Cell index={3}>
						<Form.Item name="hours" initialValue={0.0} validateTrigger="onBlur">
							<InputNumber
								step={0.01}
								controls={false}
								min={0}
								className={styles.normalCell}
								onBlur={e => {
									if (!e.target.value) {
										form.setFieldValue('hours', 0)
									}
								}}
								data-testid="new-row-hours"
							/>
						</Form.Item>
					</Cell>
					<Cell index={4} />
					<Cell index={5}>
						<Form.Item
							name="taxable"
							valuePropName="checked"
							initialValue={false}>
							<Checkbox>{t('canned_service.taxable')}</Checkbox>
						</Form.Item>
					</Cell>
					<Cell index={6} className={styles.actionColumn}>
						<Button
							onClick={addItem}
							type="link"
							className={styles.customLink}
							data-testid="add-button">
							<PlusOutlined /> {t('canned_service.add')}
						</Button>
					</Cell>
				</Row>
			</Form>
			<Row className={styles.customSummaryRow}>
				<Cell index={0} />
				<Cell index={1} />
				<Cell index={2} />
				<Cell index={3}>
					<Text strong>{t('canned_service.labors.subtotal')}</Text>
				</Cell>
				<Cell index={4}>
					<Text strong>{formatCurrency(total.toString())}</Text>
				</Cell>
				<Cell index={5} />
				<Cell index={6} />
			</Row>
		</Summary>
	)
}

export default TableSummary
